import React from 'react';

const BasesJSPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#5E5F62"
          d="M27.631 10.667l-6.704 1.766-.25-.917 6.712-1.768a13.604 13.604 0 00-1.939-3.911L14.776 8.648l-.25-.917 10.288-2.709a14.006 14.006 0 00-4.755-3.66L2.462 5.997A13.659 13.659 0 00.2 11.506l2.019-.532.25.917-2.402.633a13.458 13.458 0 00.307 4.34l17.288-4.553.25.916L.625 17.781c.187.6.403 1.185.663 1.742 1.334-.214 2.012 1.384 1.337 2.264.079.109.167.21.249.315l7.36-1.94.25.918-6.935 1.827a14.025 14.025 0 004.406 3.261l10.731-2.826.25.916-9.513 2.506a14.15 14.15 0 008.204.296 13.967 13.967 0 006.966-4.292l-3.108.818-.25-.916 4.322-1.139c.073-.105.147-.209.217-.315-.407-.727-.24-1.79.816-2.07.11-.028.216-.044.317-.05.35-.819.611-1.675.797-2.552l-3.024.797-.25-.917 3.45-.909a13.453 13.453 0 00-.249-4.848zM10.38.472c-.899.237-1.753.556-2.558.945.03.586-.27 1.176-1.017 1.373-.42.11-.78.04-1.065-.133a13.887 13.887 0 00-2.32 2.105L18.596.764a14.167 14.167 0 00-8.217-.292z"
        ></path>
        <ellipse cx="11.5" cy="13" fill="#77797F" rx="10.5" ry="10"></ellipse>
        <ellipse cx="9" cy="13.5" fill="#868C9B" rx="6" ry="5.5"></ellipse>
        <circle cx="0.5" cy="0.5" r="1" fill="#F3D55B"></circle>
        <circle cx="5.5" cy="27.5" r="1" fill="#F3D55B"></circle>
        <circle cx="26.5" cy="23.5" r="1" fill="#F3D55B"></circle>
        <circle cx="0.5" cy="26.5" r="1" fill="#F3D55B"></circle>
        <circle cx="2.5" cy="2.5" r="1" fill="#F3D55B"></circle>
        <circle cx="25.5" cy="1.5" r="1" fill="#F3D55B"></circle>
        <g fill="#7684A3">
          <path
            fillRule="nonzero"
            d="M24.88 17.083c-.002-.028-.001-.057-.005-.085-.112-.85-.775-1.71-1.827-1.71-.172 0-.35.025-.527.073-1.09.291-1.47 1.242-1.364 2.055.028.21.09.418.18.615l-7.782 2.083c-.292-.57-.846-1.023-1.606-1.023-.173 0-.35.025-.528.072-1.017.272-1.41 1.117-1.372 1.89l-7.175 1.92c.215.281.44.554.675.817l6.77-1.812c.302.536.839.95 1.566.95.172 0 .35-.023.528-.071.98-.263 1.383-1.058 1.377-1.808l8.244-2.206c.266.174.583.283.95.283.173 0 .35-.024.528-.072.586-.157.961-.506 1.172-.92l3.02-.808c.072-.345.13-.694.176-1.045l-3 .802zm-12.717 4.842c-.663.176-1.083-.34-1.148-.833-.025-.186-.05-.81.656-.998.096-.026.19-.04.278-.04.518 0 .814.453.87.872.024.187.05.81-.656 1zm11.1-3.802c-.663.177-1.083-.342-1.148-.833-.025-.187-.05-.81.656-.999.096-.025.19-.039.277-.039.519 0 .815.452.87.872.025.187.05.81-.656.999z"
          ></path>
          <path d="M18.596 1.294L3.419 5.356c-.345.398-.66.82-.957 1.254L20.059 1.9a14.111 14.111 0 00-1.463-.606z"></path>
          <path
            fillRule="nonzero"
            d="M3.882 13.59c.172 0 .35-.024.527-.072.981-.262 1.384-1.058 1.377-1.807l5.662-1.516c.281.606.853 1.096 1.646 1.096.172 0 .35-.024.528-.072 1.053-.282 1.439-1.178 1.368-1.971l10.46-2.8c-.2-.285-.415-.56-.636-.828L14.683 8.331c-.31-.5-.83-.878-1.524-.878-.173 0-.35.024-.528.072-.945.253-1.355 1-1.378 1.724l-5.7 1.526c-.293-.57-.847-1.023-1.607-1.023-.172 0-.35.024-.528.072-1.017.272-1.41 1.117-1.372 1.89L.2 12.206c-.057.342-.102.687-.133 1.034l2.25-.603c.301.537.837.952 1.565.952zm8.998-5.134c.097-.025.19-.039.278-.039.519 0 .814.453.87.872.025.187.05.81-.656.999-.661.176-1.082-.342-1.148-.833-.024-.187-.05-.81.656-.999zm-9.212 2.3c.096-.026.19-.04.277-.04.52 0 .815.452.87.872.025.187.05.81-.656.999-.665.177-1.082-.342-1.147-.833-.025-.187-.05-.81.656-.999zm18.035 12.583c-.112-.85-.775-1.711-1.827-1.711-.172 0-.35.024-.528.072-1.09.292-1.47 1.243-1.362 2.056.027.208.088.418.18.614L7.955 27.103c.477.23.966.432 1.467.606l9.44-2.526c.265.173.583.283.95.283.173 0 .35-.024.528-.072.587-.157.962-.507 1.172-.92l3.08-.825c.346-.4.667-.82.965-1.256l-3.85 1.03c-.001-.028 0-.057-.004-.084zm-1.613 1.124c-.661.176-1.082-.342-1.147-.834-.025-.186-.05-.81.656-.998.096-.026.19-.04.277-.04.519 0 .815.453.87.873.025.187.05.81-.656.999zm-.883-9.484c.172 0 .35-.024.527-.072.982-.262 1.384-1.058 1.377-1.807l6.52-1.745c-.036-.157-.063-.314-.105-.471-.042-.156-.09-.31-.137-.462l-6.512 1.742c-.292-.57-.846-1.023-1.606-1.023-.173 0-.35.024-.528.072-1.017.273-1.41 1.117-1.372 1.89L.373 17.65c.036.157.065.314.107.47.042.158.098.307.145.461l17.016-4.553c.302.535.838.95 1.566.95zm-.214-2.834c.096-.026.19-.04.277-.04.519 0 .815.453.87.872.025.187.05.81-.656.999-.662.176-1.082-.341-1.147-.833-.025-.186-.05-.81.656-.998z"
          ></path>
        </g>
      </g>
    </svg>
  );
};
export default BasesJSPlanet;
