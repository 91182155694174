import React from 'react';

const IntegrationPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="33">
      <g fill="none" fillRule="evenodd">
        <circle cx="15" cy="19" r="14" fill="#8697CB"></circle>
        <circle cx="15" cy="17" r="11" fill="#92A8DD"></circle>
        <circle cx="11.5" cy="15.5" r="6.5" fill="#A4B9ED"></circle>
        <path
          fill="#F3D55B"
          d="M32.417 2.917h-.926l-1.408-1.408V.582a.583.583 0 10-1.166 0v.925l-1.409 1.409h-.925a.583.583 0 100 1.166h.926l1.408 1.409v.925a.583.583 0 101.166 0v-.925l1.409-1.409h.925a.583.583 0 100-1.166z"
        ></path>
        <circle cx="4.5" cy="4.5" r="1" fill="#F3D55B"></circle>
        <circle cx="31.5" cy="12.5" r="1" fill="#F3D55B"></circle>
        <circle cx="24.5" cy="1.5" r="1" fill="#F3D55B"></circle>
        <circle cx="18.5" cy="7.5" r="1" fill="#F3D55B"></circle>
        <circle cx="20.5" cy="27.5" r="1" fill="#F3D55B"></circle>
        <circle cx="1.5" cy="32.5" r="1" fill="#F3D55B"></circle>
        <circle cx="0.5" cy="26.5" r="1" fill="#F3D55B"></circle>
        <path
          fill="#556080"
          d="M19 13.866c-1.437 0-2.282-.953-2.544-2.083-.146-.632-.825-.978-1.368-.667-1.208.69-2.04 2.013-2.086 3.547-.07 2.3 1.737 4.297 3.929 4.336 2.24.04 4.069-1.856 4.069-4.2l-.001-.108c-.016-.644-.62-1.112-1.21-.936a2.74 2.74 0 01-.79.111zM5 9.462C5.24 10.902 6.476 12 7.974 12A3.035 3.035 0 0011 8.956 3.037 3.037 0 008.671 6 16.256 16.256 0 005 9.462z"
        ></path>
        <path
          stroke="#556080"
          strokeLinecap="round"
          strokeWidth="2"
          d="M26 12.817c-.742.434-1.636.07-2-.817m-8-6c0 1.105-.895 2-2 2m0 13c0 1.105-.895 2-2 2m12-4c.826.324 1.219 1.218.876 2M6 19c.826.324 1.219 1.218.876 2"
        ></path>
      </g>
    </svg>
  );
};
export default IntegrationPlanet;
