import styled from 'src/style';
import colors from 'src/style/colors';
import { Paragraph, Sentence } from '../../Typography/index';
import { mediaQueries } from 'src/style/breakpoints';

export const TabsTabletLandscapeUp = styled.ul({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '0 64px',
  borderBottom: `3px solid ${colors.lightGrey}`,
});

export const Heading = styled(Sentence)({
  color: colors.purpleDark,
  fontSize: 22,
  fontWeight: 'bold',
  [mediaQueries.phoneOnly]: {
    fontSize: 17,
    lineHeight: '24px',
  },
});

export const Content = styled(Paragraph)({
  padding: 0,
  paddingTop: 8,
  [mediaQueries.phoneOnly]: {
    fontSize: 17,
    lineHeight: '24px',
  },
  '& a': {
    color: colors.purple,
  },
});
