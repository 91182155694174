import React from 'react';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';
import { ArrowColored } from '../Icons/arrows';
import PlanetIcon from 'src/components/Bootcamp/Schedule/Program/PlanetIcon';

export const TabsList = ({ tab, isOpen, onClick, className }) => {
  return (
    <li
      className={className}
      css={{
        display: 'flex',
        flex: 1,
        height: '120px',
        // justifyContent: 'center',
        alignItems: 'center',

        fontWeight: 'bold',
        fontSize: 19,
        listStyle: 'none',
        position: 'relative',
        width: '100%',
        textAlign: 'center',
        color: isOpen ? colors.purple : 'lightgrey',
        cursor: 'pointer',
        '&:after': {
          content: '""',
          width: '100%',
          opacity: isOpen ? 1 : 0,
          top: '89px',
          left: 0,
          height: 4,
          borderLeft: `5px solid ${colors.white}`,
          borderRight: `5px solid ${colors.white}`,
          backgroundColor: colors.purple,
          position: 'absolute',
          margin: '30px 0px',
          transitionProperty: 'opacity',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: '0s',
        },
        '&:hover': {
          color: colors.purple,
        },
        '&:hover:after': {
          opacity: 1,
          transitionProperty: 'opacity',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: '0s',
        },
      }}
    >
      <button
        css={{
          width: '100%',
          padding: '30px 0px',
        }}
        onClick={onClick}
      >
        {Array.isArray(tab) ? (
          <div>
            {tab[0]}
            <br />
            {tab[1]}
          </div>
        ) : (
          tab
        )}
      </button>
    </li>
  );
};

export const TabsListMobile = ({
  isUnfolded,
  tabItem,
  tabSubtitle,
  onClick,
  children,
  isProgramSection,
  planetName,
}) => {
  return (
    <button
      onClick={onClick}
      css={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        cursor: 'pointer',
        backgroundColor: colors.white,
        marginBottom: 8,
        padding: 32,
        borderRadius: 10,
        boxShadow: '0 5px 15px rgba(0,0,0,.04)',
        [mediaQueries.phoneOnly]: {
          padding: '28px 24px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingBottom: isUnfolded ? 32 : null,
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {isProgramSection && (
            <div
              css={{
                width: '36px',
                marginRight: 22,
                alignSelf: tabSubtitle && 'center',
              }}
            >
              <PlanetIcon tag={planetName} />
            </div>
          )}
          <div>
            <li
              css={{
                fontWeight: 'bold',
                listStyle: 'none',
                position: 'relative',
                fontSize: 19,
                lineHeight: '26px',
                color: isProgramSection ? colors.purpleDark : colors.purple,
                [mediaQueries.phoneOnly]: {
                  lineHeight: '22px',
                  fontSize: 18,
                },
              }}
            >
              {Array.isArray(tabItem) ? <div>{tabItem[0] + ' - ' + tabItem[1]}</div> : tabItem}
            </li>
            <li
              css={{
                listStyle: 'none',
                fontSize: 17,
                color: isProgramSection ? colors.purpleDark : colors.purple,
                [mediaQueries.phoneOnly]: {
                  lineHeight: '24px',
                },
              }}
            >
              {tabSubtitle}
            </li>
          </div>
        </div>

        <div
          css={{
            display: 'block',
            transform: isUnfolded ? 'rotate(90deg)' : '',
            [mediaQueries.phoneOnly]: {
              marginLeft: 18,
            },
            [mediaQueries.tabletLandscapeUp]: {
              display: 'none',
            },
          }}
        >
          <ArrowColored color={isProgramSection ? colors.purpleDark : colors.purple} />
        </div>
      </div>
      {children}
    </button>
  );
};
