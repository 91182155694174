import React from 'react';

const SitesWebPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="33">
      <g fill="none" fillRule="evenodd">
        <circle cx="15" cy="18" r="14" fill="#D75A4A"></circle>
        <path
          fill="#F3D55B"
          d="M32.417 2.917h-.926l-1.408-1.408V.582a.583.583 0 10-1.166 0v.925l-1.409 1.409h-.925a.583.583 0 100 1.166h.926l1.408 1.409v.925a.583.583 0 101.166 0v-.925l1.41-1.409h.924a.583.583 0 100-1.166z"
        ></path>
        <circle cx="2.5" cy="5.5" r="1" fill="#F3D55B"></circle>
        <circle cx="14.5" cy="7.5" r="1" fill="#F3D55B"></circle>
        <circle cx="31.5" cy="10.5" r="1" fill="#F3D55B"></circle>
        <circle cx="21.5" cy="2.5" r="1" fill="#F3D55B"></circle>
        <circle cx="20.5" cy="26.5" r="1" fill="#F3D55B"></circle>
        <circle cx="1.5" cy="32.5" r="1" fill="#F3D55B"></circle>
        <circle cx="0.5" cy="25.5" r="1" fill="#F3D55B"></circle>
        <path
          fill="#E57E25"
          d="M28 12c-.519-1.454-1.2-2.8-2.017-4C22.093 8.765 14 10.379 14 10.379L28 12zm.702 13A17.767 17.767 0 0030 21l-19 2.436s11.176.962 17.702 1.564zM1.224 15A18.82 18.82 0 001 17.874c0 .379.015.754.037 1.126L14 17.178S6.183 15.82 1.224 15z"
        ></path>
        <circle cx="25.5" cy="32.5" r="1" fill="#F3D55B"></circle>
        <circle cx="31.5" cy="25.5" r="1" fill="#F3D55B"></circle>
        <circle cx="0.5" cy="2.5" r="1" fill="#F3D55B"></circle>
        <circle cx="6.5" cy="1.5" r="1" fill="#F3D55B"></circle>
      </g>
    </svg>
  );
};
export default SitesWebPlanet;
