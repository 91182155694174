import React from 'react';

const IntershipPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="35">
      <g fill="none" fillRule="evenodd" transform="translate(-722 -3224)">
        <g fillRule="nonzero">
          <path
            fill="#38454F"
            d="M743.59 3252.39v-5.328c0-.327.276-.592.616-.592.34 0 .616.265.616.592v1.184h7.936c-.656-2.545-3.89-4.804-6.116-6.294-.93-.623-1.31-1.612-1.82-2.586-.961-1.834-1.97-4.427-2.321-6.46l-.327-1.816c-.034.006-.069.014-.103.014a.622.622 0 01-.52-.271c-.65-.972-1.606-1.53-2.62-1.53-.988 0-1.926.53-2.574 1.455a.627.627 0 01-.615.25l-.341 1.898c-.353 2.033-1.322 4.626-2.283 6.46-.51.974-.929 1.963-1.86 2.586-2.226 1.49-5.423 3.75-6.076 6.294h7.936v-1.184c0-.327.276-.592.616-.592.34 0 .616.265.616.592v5.328c0 .283-.212.508-.488.567a.291.291 0 00.116.025h1.238c.118 0 .223.07.265.175l.568 1.425a.285.285 0 00.265.176h5.272c.118 0 .223-.07.266-.176l.568-1.425a.284.284 0 01.265-.175h1.237a.286.286 0 00.125-.031.589.589 0 01-.457-.561z"
          ></path>
          <path
            fill="#38454F"
            d="M744.273 3251.576c.005 0 .01-.005.015-.006l7.134-.708c.156-.015.275-.175.275-.367v-1.04h-7.424v2.12zm-19.091-2.121v1.034c0 .19.12.35.275.365l7.098.703c.019.002.033.015.051.019v-2.121h-7.424z"
          ></path>
          <path
            fill="#546A79"
            d="M734.727 3253.14v-5.226c0-.32-.285-.58-.636-.58-.351 0-.636.26-.636.58v1.162h-8.198c-.027.098-.057.196-.075.296v.865h8.273v1.757c.34.059.595.324.595.645v.815c0 .108.072.201.174.243.285-.058.503-.279.503-.556zm17.955-4.063h-8.197v-1.162c0-.321-.285-.582-.637-.582-.35 0-.636.26-.636.582v5.23c0 .269.202.485.472.552a.266.266 0 00.164-.238v-.814c0-.335.275-.615.637-.657v-1.748h8.273v-.866c-.019-.1-.05-.198-.076-.297z"
          ></path>
          <g fill="#F3D55B" transform="translate(722 3224)">
            <path d="M32.118 3.03h-.966l-1.47-1.463v-.96A.608.608 0 0029.073 0a.608.608 0 00-.608.606v.961l-1.47 1.463h-.966a.608.608 0 00-.609.606c0 .335.273.606.609.606h.966l1.47 1.463v.962a.608.608 0 001.218 0v-.962l1.469-1.463h.966a.608.608 0 00.609-.606.608.608 0 00-.61-.606z"></path>
            <circle cx="26.029" cy="6.667" r="1"></circle>
            <circle cx="1.066" cy="19.394" r="1"></circle>
            <circle cx="1.674" cy="4.242" r="1"></circle>
            <circle cx="8.372" cy="9.697" r="1"></circle>
            <circle cx="30.9" cy="18.182" r="1"></circle>
            <circle cx="5.936" cy="32.121" r="1"></circle>
            <circle cx="29.682" cy="32.727" r="1"></circle>
          </g>
          <path
            fill="#E57E25"
            d="M735.77 3256.879c-.341 0-.618.237-.618.53v.53c0 .293.277.53.618.53s.62-.237.62-.53v-.53c0-.293-.278-.53-.62-.53zm1.856 0c-.341 0-.618.237-.618.53v1.06c0 .293.277.531.618.531s.62-.238.62-.53v-1.06c0-.294-.278-.531-.62-.531zm1.857 0c-.342 0-.62.237-.62.53v1.06c0 .293.278.531.62.531s.618-.238.618-.53v-1.06c0-.294-.277-.531-.618-.531zm1.856 0c-.342 0-.62.237-.62.53v.53c0 .293.278.53.62.53s.618-.237.618-.53v-.53c0-.293-.277-.53-.618-.53z"
          ></path>
          <path
            fill="#38454F"
            d="M738.417 3228.108c.993 0 1.928.426 2.674 1.195l-.263-1.59c-.243-1.525-.859-2.214-1.78-3.408a.766.766 0 00-1.224 0c-.922 1.194-1.537 1.883-1.78 3.407l-.256 1.545c.74-.739 1.66-1.149 2.63-1.149z"
          ></path>
          <path
            fill="#8697CB"
            d="M738.943 3229.505c1.003 0 1.949.594 2.593 1.63.116.187.313.29.513.29.034 0 .068-.01.103-.016l-.316-1.886c-.807-.824-1.82-1.28-2.893-1.28-1.05 0-2.046.44-2.847 1.232l-.308 1.848a.6.6 0 00.609-.267c.64-.986 1.569-1.551 2.546-1.551z"
          ></path>
          <path
            fill="#546A79"
            d="M741.378 3247.923h-5.568c-.342 0-.619.264-.619.589 0 .325.277.589.619.589h5.568c.342 0 .619-.264.619-.59 0-.324-.277-.588-.619-.588zm0-2.947h-5.568c-.342 0-.619.264-.619.59 0 .325.277.589.619.589h5.568c.342 0 .619-.264.619-.59 0-.325-.277-.589-.619-.589zm-5.568-1.767h5.568c.342 0 .619-.264.619-.59 0-.325-.277-.589-.619-.589h-5.568c-.342 0-.619.264-.619.59 0 .325.277.589.619.589zm4.95 7.07h-4.331c-.342 0-.619.264-.619.59v1.178c0 .325.277.59.619.59s.618-.265.618-.59v-.59h3.094v.59c0 .325.277.59.618.59s.62-.265.62-.59v-1.178c0-.326-.278-.59-.62-.59zM746.164 3242.42c-.872-.612-1.227-1.583-1.704-2.538-.9-1.8-1.843-4.341-2.175-6.337-.402 3.88-.415 12.69 7.76 12.728.21 0 .404-.006.591-.017-1.236-1.524-3.068-2.852-4.472-3.837zm-11.585-9.935l-.021.134c-.33 2.14-1.236 4.87-2.136 6.8-.477 1.025-.869 2.066-1.74 2.722-1.402 1.055-3.215 2.479-4.44 4.113.19.012.388.02.602.019 8.26-.041 8.15-9.708 7.735-13.788z"
          ></path>
        </g>
      </g>
    </svg>
  );
};
export default IntershipPlanet;
