import React from 'react';

const TravailPrepPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="28">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#C68630"
          d="M20.097 14c0-.266.216-.483.483-.483H28a13.935 13.935 0 00-.858-4.38.479.479 0 01-.176.035H15.093a.483.483 0 110-.965h11.662c-.347-.763-.76-1.49-1.234-2.172a.476.476 0 01-.408.24h-4.832a.483.483 0 110-.965h4.701A13.987 13.987 0 0014 0C8.33 0 3.45 3.367 1.245 8.207h10.659a.483.483 0 110 .965H1.034a.475.475 0 01-.176-.035A13.936 13.936 0 000 13.517h4.21a.483.483 0 110 .966H0c.058 1.72.425 3.36 1.05 4.87a.475.475 0 01.195-.043h9.828a.483.483 0 110 .966H1.476a14.056 14.056 0 003.62 4.534.473.473 0 01.374-.19h10.369a.483.483 0 110 .966H6.133C8.376 27.11 11.083 28 14 28s5.624-.89 7.867-2.414H18.93a.483.483 0 110-.965h3.6c.155 0 .286.076.375.189a14.055 14.055 0 003.62-4.534h-12.44a.483.483 0 110-.966h12.67a.48.48 0 01.194.042c.625-1.508.992-3.15 1.05-4.87h-7.42a.483.483 0 01-.482-.482z"
        ></path>
        <circle cx="10.595" cy="12.108" r="9.838" fill="#DC9628"></circle>
        <circle cx="10.595" cy="11.351" r="5.297" fill="#EEAF4B"></circle>
        <path
          fill="#FAC176"
          d="M14.115 19.676c-.272 0-.493.339-.493.756 0 .418.22.757.493.757H26.81c.155-.474.3-.956.433-1.448a.351.351 0 00-.197-.065H14.115z"
        ></path>
        <path
          fill="#F18D46"
          d="M11.351 20.432c0-.417-.202-.756-.452-.756H1.695a.302.302 0 00-.181.066c.121.491.255.973.397 1.447H10.9c.25 0 .452-.339.452-.757z"
        ></path>
        <path
          fill="#F3D55B"
          d="M4.54 14.378c0-.417-.208-.756-.466-.756H.012c-.005.252-.012.503-.012.756 0 .254.007.505.012.757h4.062c.258 0 .467-.339.467-.757z"
        ></path>
        <path
          fill="#F09372"
          d="M19.676 5.297c0 .418.229.757.51.757h5.112c.186 0 .343-.156.432-.377a23.691 23.691 0 00-.571-1.136h-4.972c-.282 0-.511.339-.511.756z"
        ></path>
        <path
          fill="#F29C1F"
          d="M27.988 13.622h-7.094c-.255 0-.462.339-.462.756 0 .418.207.757.462.757h7.094c.006-.252.012-.503.012-.757 0-.253-.006-.504-.012-.756z"
        ></path>
        <path
          fill="#FAC176"
          d="M15.892 24.973c0-.418-.204-.757-.456-.757H5.65c-.145 0-.27.12-.353.297.315.43.642.835.98 1.217h9.159c.252 0 .456-.34.456-.757z"
        ></path>
        <path
          fill="#F18D46"
          d="M22.322 24.216h-3.668c-.271 0-.492.34-.492.757 0 .418.22.757.492.757h2.991c.365-.382.719-.787 1.058-1.217-.09-.176-.223-.297-.38-.297z"
        ></path>
        <path
          fill="#FAC176"
          d="M14.378 9.081c0 .418.21.757.467.757h11.471c.06 0 .117-.022.17-.056a30.36 30.36 0 00-.373-1.458H14.845c-.258 0-.467.34-.467.757z"
        ></path>
        <path
          fill="#F3D55B"
          d="M11.664 9.838c.245 0 .444-.34.444-.757s-.199-.757-.444-.757H1.87c-.127.478-.248.963-.355 1.458.05.034.104.056.162.056h9.988z"
        ></path>
        <g fill="#F3D55B" transform="translate(.757)">
          <circle cx="1.456" cy="1.892" r="1"></circle>
          <circle cx="2.913" cy="26.014" r="1"></circle>
          <circle cx="0.485" cy="22.703" r="1"></circle>
          <circle cx="5.826" cy="0.473" r="1"></circle>
          <circle cx="25.244" cy="1.892" r="1"></circle>
        </g>
        <path
          fill="#F3D55B"
          d="M17.686 13.622H8.043c-.262 0-.475.339-.475.756 0 .418.213.757.475.757h9.644c.262 0 .475-.339.475-.757 0-.417-.213-.756-.476-.756z"
        ></path>
        <path
          fill="#F0C419"
          d="M19.957 16.649h-9.644c-.262 0-.475.339-.475.756s.213.757.475.757h9.644c.262 0 .475-.339.475-.757s-.213-.756-.475-.756z"
        ></path>
        <path
          fill="#FAC176"
          d="M17.687 23.46H8.043c-.262 0-.475-.34-.475-.757s.213-.757.475-.757h9.644c.262 0 .475.339.475.757s-.213.756-.475.756z"
        ></path>
        <path
          fill="#F3D55B"
          d="M16.144 11.351H11.1c-.278 0-.504-.339-.504-.756s.226-.757.504-.757h5.045c.279 0 .505.339.505.757 0 .417-.226.756-.505.756z"
        ></path>
        <path
          fill="#F0C419"
          d="M7.063 9.838H2.018c-.278 0-.504.339-.504.757 0 .417.226.756.504.756h5.045c.279 0 .505-.339.505-.756s-.226-.757-.505-.757z"
        ></path>
        <path
          fill="#F3D55B"
          d="M16.144 6.054H11.1c-.278 0-.504-.339-.504-.757 0-.417.226-.756.504-.756h5.045c.279 0 .505.339.505.756s-.226.757-.505.757zm-3.01-2.27H8.055c-.269 0-.487-.34-.487-.757 0-.418.218-.757.487-.757h5.08c.268 0 .487.34.487.757s-.219.757-.488.757zm6.859 0h-3.662c-.242 0-.44-.34-.44-.757 0-.418.198-.757.44-.757h3.662c.243 0 .44.34.44.757s-.197.757-.44.757z"
        ></path>
      </g>
    </svg>
  );
};
export default TravailPrepPlanet;
