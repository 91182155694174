import React from 'react';

const MvpPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="36">
      <g fill="none" fillRule="evenodd">
        <ellipse cx="17.5" cy="17" fill="#9FBDC2" rx="13.5" ry="14"></ellipse>
        <ellipse cx="14.5" cy="17" fill="#9FCACC" rx="10.5" ry="11"></ellipse>
        <circle cx="12" cy="16" r="9" fill="#ADD7DB"></circle>
        <path
          fill="#F3D55B"
          d="M30.417 30.917h-1.75l-.584-.584v-1.75a.584.584 0 00-1.166 0v1.75l-.584.584h-1.75a.584.584 0 000 1.166h1.75l.584.584v1.75a.584.584 0 001.166 0v-1.75l.584-.584h1.75a.584.584 0 000-1.166z"
        ></path>
        <circle cx="1.5" cy="3.5" r="1" fill="#F3D55B"></circle>
        <circle cx="4.5" cy="33.5" r="1" fill="#F3D55B"></circle>
        <circle cx="0.5" cy="29.5" r="1" fill="#ECF0F1"></circle>
        <circle cx="7.5" cy="0.5" r="1" fill="#F3D55B"></circle>
        <circle cx="31.5" cy="3.5" r="1" fill="#F3D55B"></circle>
        <circle cx="32.5" cy="31.5" r="1" fill="#F3D55B"></circle>
        <circle cx="25.5" cy="23.5" r="1" fill="#F3D55B"></circle>
        <circle cx="7.5" cy="16.5" r="1" fill="#F3D55B"></circle>
        <path
          fill="#ECF0F1"
          d="M16.66 31.786c-.97 2.453-1.732 3.048-2.036 3-.354-.051-1.104-1.146-1.404-4.913-.267-3.339-.108-7.711.446-12.313C14.901 7.314 17.448 1.052 18.67 1.212c.284.042.846.761 1.206 3.146.371.098.735.216 1.09.35a.652.652 0 00.029-.281c-.388-2.811-1.101-4.255-2.18-4.414C15.83-.423 13.453 10.17 12.582 17.4c-.565 4.688-.725 9.155-.452 12.58.303 3.8 1.093 5.821 2.35 6.006.063.01.127.014.19.014 1.049 0 2.055-1.287 3.069-3.922a.65.65 0 00.03-.32c-.367.027-.736.036-1.109.028z"
        ></path>
      </g>
    </svg>
  );
};
export default MvpPlanet;
