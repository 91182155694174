import React from 'react';

const ServeurPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="33" height="35">
      <g fill="none" fillRule="evenodd">
        <ellipse cx="16" cy="17.5" fill="#F9C38A" rx="12" ry="12.5"></ellipse>
        <ellipse cx="15.5" cy="15" fill="#FFD199" rx="8.5" ry="9"></ellipse>
        <ellipse cx="14" cy="13" fill="#FFD8AE" rx="4" ry="5"></ellipse>
        <path
          fill="#F3D55B"
          d="M6.417 3.917h-.925L4.083 2.509v-.926a.583.583 0 10-1.166 0v.926L1.509 3.917H.582a.583.583 0 100 1.166h.925l1.409 1.409v.925a.583.583 0 101.166 0v-.925l1.409-1.409h.925a.583.583 0 100-1.166z"
        ></path>
        <circle cx="28.5" cy="33.5" r="1" fill="#F3D55B"></circle>
        <circle cx="31.5" cy="21.5" r="1" fill="#F3D55B"></circle>
        <circle cx="2.5" cy="13.5" r="1" fill="#F3D55B"></circle>
        <circle cx="13.5" cy="15.5" r="1" fill="#F3D55B"></circle>
        <circle cx="17.5" cy="33.5" r="1" fill="#F3D55B"></circle>
        <path
          fill="#F9EAB0"
          d="M20.488 5.492l-.004.005c.07.026.004-.005.004-.005zM32.874.134c-.734-.777-4.525 1.882-9.597 6.507.332.23.65.48.956.742 3.39-3.064 5.834-4.897 7.09-5.604-1.16 2.313-5.498 8.409-13.608 17.01-8.11 8.6-13.856 13.203-16.038 14.432.723-1.442 2.683-4.356 6.02-8.43a12.592 12.592 0 01-.689-1.015C2.191 29.608-.647 34.046.126 34.866c1.119 1.186 9.356-5.627 18.399-15.218C27.568 10.057 33.992 1.32 32.874.134z"
        ></path>
      </g>
    </svg>
  );
};
export default ServeurPlanet;
