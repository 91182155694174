import React from 'react';

const AppMobilesPlanet = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="27">
      <g fill="none" fillRule="evenodd">
        <ellipse cx="14" cy="13.5" fill="#285680" rx="14" ry="13.5"></ellipse>
        <path
          fill="#24AE5F"
          d="M14.107 0c-2.004 0-3.911.387-5.645 1.086-.08.447-.474 1.21-.462 1.453.056.01 3.666-3.483 3.218-1.125-.289 1.518-3.24 1.51-3.113 3.038 3.1 1.15 4.182-2.185 6.816-2.172.879.005 3.129-1.31 3.558-1.042 1.738 1.084-.397 4.926-1.727 3.647-1.28-1.21-3.104.425-3.022 2.312.665.029.949-1.786 1.546-1.181.659.667.42 2.01-.49 2.318-.917.31-4.513-2.301-3.63-.322.972 2.197-2.91 3.895 1.009 3.01 1.847-.42 1.233.91 2.105 1.546 1.038.754.579-1.042 1.335-.769 1.102.403-.189 1.443 1.536.688 2.023-.873 1.263.489 1.882 1.38 1.162 1.662 4.017 3.257 5.513 1.06.352-.518.126-4.616 2.347-1.867 1.41 1.746.926-2.063 1.117-3.064C26.312 4.233 20.733 0 14.107 0z"
        ></path>
        <path
          fill="#FAC176"
          d="M15.626 19.374l-2.61-1.92-1.75.068-1.815-.56L8.031 16l-3.223.731-1.33.63L2 19.318l.523 2.292c.15.19.304.375.463.556l2.737 1.255 1.848.244L8.67 25.88l.033.222c1.558.58 3.246.899 5.01.899 1.218 0 2.399-.15 3.526-.435l.371-2.177 1.689-1.344.701-2.147-3.525-.014-.849-1.509z"
        ></path>
        <path
          fill="#88C057"
          d="M.169 16c.062-.006.07-.012.076-.018.584-.636 1.071-.743 1.559-1.698.487-.956-.098-.956 0-2.549.097-1.592-.098-.849 0-2.548.035-.61-.024-.947-.706-1.187A15.293 15.293 0 000 13.717c0 .777.06 1.54.169 2.283z"
        ></path>
        <path
          fill="#FAC176"
          d="M13.792 24.638c-.51-.039-2.397-.63-2.83-1.416-.433-.788-1.376-2.126-1.415-1.89-.027.16-4.298-1.295-7.231-2.332L2 19.426l.506 2.26c.144.186.294.368.447.547l2.645 1.237 1.786.241 1.062 2.183.032.22c1.506.571 3.137.886 4.842.886a13.67 13.67 0 003.408-.429L17 24.944c-.24-.075-.517-.174-.85-.306-1.297-.511-1.847.04-2.358 0z"
        ></path>
        <path
          fill="#88C057"
          d="M20.286 24.11L21 21.945l-3.59-.015-.865-1.522-2.659-1.939-1.782.069-1.848-.566L8.809 17l-3.282.738-1.353.637L3 19.913c3.092 1.062 7.592 2.553 7.62 2.389.042-.242 1.036 1.128 1.492 1.934.456.806 2.444 1.41 2.982 1.451.539.04 1.119-.524 2.486 0 .35.135.642.236.895.313l.091-.532 1.72-1.357zM18.84 1c-.093 1.737-.162 2.992-.162 2.992L16 6.643s2.511 3.562 2.68 3.977c.167.414 2.26.911 2.679.662.418-.248 2.009-1.574 2.679-.662.466.634.406 1.188.693 1.577.197.003.444.134.752.441.435.13.932.256 1.374.362.146-.85.038-2.364.143-2.944-1.156-4.123-4.205-7.46-8.16-9.056z"
        ></path>
        <path
          fill="#FAC176"
          d="M20.93 3c-.08 1.542-.146 2.766-.146 2.766L19 7.552s1.672 2.4 1.784 2.679c.111.279 1.505.614 1.783.446.279-.167 1.338-1.06 1.784-.446.446.614.167 1.116.892 1.34.612.188 1.456.375 1.705.429.004-.497.005-.956.052-1.216A12.97 12.97 0 0020.93 3z"
        ></path>
      </g>
    </svg>
  );
};
export default AppMobilesPlanet;
