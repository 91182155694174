import React from 'react';
import {
  TravailPrepPlanet,
  AppMobilesPlanet,
  BasesJSPlanet,
  DemoDayPlanet,
  IntegrationPlanet,
  InternshipPlanet,
  MvpPlanet,
  SitesWebPlanet,
  ServeurPlanet,
} from 'src/components/Icons/Planets/index';

const components = {
  travailplanet: TravailPrepPlanet,
  appplanet: AppMobilesPlanet,
  JSplanet: BasesJSPlanet,
  demodayplanet: DemoDayPlanet,
  integrationplanet: IntegrationPlanet,
  internshipplanet: InternshipPlanet,
  mvpplanet: MvpPlanet,
  siteplanet: SitesWebPlanet,
  serveurplanet: ServeurPlanet,
};

const PlanetIcon = ({ tag }) => {
  const TagName = components[tag];

  return <TagName />;
};

export default PlanetIcon;
